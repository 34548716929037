<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Cambiar password</h1>
        <hr class="">
      </v-col>
    </v-row>
      <v-form ref="form" @submit.prevent="submitForm">
        <v-row class="mx-sm-8 mx-2">
          <v-col cols="12" lg="6">
            <p >Contraseña</p>
            <v-text-field
                outlined
                v-model="data.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :rules="[rules.required]"
                @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <p >Confirmar contraseña</p>
            <v-text-field
                outlined
                v-model="data.password_confirmed"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                :rules="[rules.required]"
                @click:append="show2 = !show2"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn type="submit" color="primary" style="width: 200px">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";

export default {
  name: "ChangePassword",
  data() {
    return {
      overlay: false,
      show1: false,
      show2: false,
      data: {
        password: "",
        password_confirmed: "",
      },
      rules: {
        required: value => !!value || 'Este campo es requerido.',
      },
    };
  },
  async created(){
    this.id = this.$route.params.id;
    await this.findData();

  },
  components: {
    Overlay
  },
  methods: {
    ...mapActions('users', ['changePasswordUser','findOneUser']),
    async findData(){
      const response = await this.findOneUser(this.id);
      if (response.user){
       return
      }
     await this.$router.push('/usuarios')

    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        const response = await this.changePasswordUser({data:this.data,id:this.id});
        if (!response.error) {
          this.overlay = true;
          this.sweetAlertSuccess('Se edito correctamente')
          await this.$router.push('/usuarios')
        } else {
          this.overlay = false;
          this.sweetAlertError(response.error)
        }
      }
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
